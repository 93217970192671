import { afterGameTypes } from './actions';

const initialState = {
	afterGameTab: 1,
};

const handlers = {
	[afterGameTypes.SET_AFTER_TAB]: (state, { payload }) => ({ ...state, afterGameTab: payload }),
	DEFAULT: (state) => state,
};

const afterGameReducer = (state = initialState, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action);
};

export default afterGameReducer;
