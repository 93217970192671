import { pageTypes } from './actions';

const initialState = {
	section: 0,
};

const handlers = {
	[pageTypes.SET_SECTION]: (state, { payload }) => ({ ...state, section: payload }),
	DEFAULT: (state) => state,
};

const pageReducer = (state = initialState, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action);
};

export default pageReducer;
