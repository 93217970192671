export const authTypes = {
	SET_LOGGED_IN: 'SET_LOGGED_IN',
	SET_USER_DATA: 'SET_USER_DATA',
	SET_PARTICIPANT: 'SET_PARTICIPANT',
	SET_EVENTS: 'SET_EVENTS',
};

export const setLoggedIn = (payload) => ({
	type: authTypes.SET_LOGGED_IN,
	payload,
});

export const setUserData = (payload) => ({
	type: authTypes.SET_USER_DATA,
	payload,
});

export const setIsParticipant = (payload) => ({
	type: authTypes.SET_PARTICIPANT,
	payload,
});

export const setEvents = (payload) => ({
	type: authTypes.SET_EVENTS,
	payload,
});
