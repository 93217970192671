import { isBrowser } from './ssr';
import Cookies from 'js-cookie';
let amplitude;

if (isBrowser) {
	amplitude = require('amplitude-js');
}

export const initAmplitude = () => {
	amplitude.getInstance().init(
		process.env.GATSBY_AMPLITUDE_ID,
		null,
		{
			includeUtm: true,
			includeReferrer: true,
			domain: '.scope.gg',
			saveParamsReferrerOncePerSession: false,
			unsetParamsReferrerOnNewSession: true,
		},
		(instance) => {
			const amplitudeDeviceId = instance.options.deviceId;

			if (amplitudeDeviceId && amplitudeDeviceId !== 'undefined') {
				Cookies.set('device-id', amplitudeDeviceId, {
					expires: 365,
					path: '/',
					domain: process.env.GATSBY_COOKIE_DOMAIN,
				});
			}
		}
	);
};

export function sendEvent(name, payload) {
	if (isBrowser) {
		amplitude.getInstance().logEvent(name, payload);
	}
}

export function sendIdentify(property, value) {
	if (isBrowser) {
		const identify = new amplitude.Identify().append(property, value);

		amplitude.identify(identify);
	}
}
