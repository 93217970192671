import { beforeGameTypes } from './actions';

const initialState = {
	beforeGameTab: 1,
};

const handlers = {
	[beforeGameTypes.SET_BEFORE_TAB]: (state, { payload }) => ({ ...state, beforeGameTab: payload }),
	DEFAULT: (state) => state,
};

const beforeGameReducer = (state = initialState, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action);
};

export default beforeGameReducer;
