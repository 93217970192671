import axios from 'axios';

export let axiosInstance = null;

export const initAxios = (apiUrl, headers) => {
	axiosInstance = axios.create({
		baseURL: apiUrl,
		timeout: 60000,
		headers: {
			...headers,
			credentials: 'same-origin',
			'content-type': 'application/json',
		},
		withCredentials: true,
	});

	axiosInstance.interceptors.response.use(
		(response) => {
			if ('data' in response) {
				return response.data;
			}

			return response;
		},
		(error) => {
			console.error('Interceptor error', error, error.message);

			if (error.response.status === 401) {
				return Promise.reject();
			}

			return Promise.reject({
				code: error.response.status,
			});
		}
	);
};
