import { loginPopupTypes } from './actions';

const initialState = {
	isOpen: false,
};

const handlers = {
	[loginPopupTypes.SET_OPEN]: (state, { payload }) => ({ ...state, isOpen: payload }),
	DEFAULT: (state) => state,
};

const loginPopupReducer = (state = initialState, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action);
};

export default loginPopupReducer;
