import { authTypes } from './actions';

const initalState = {
	isLoggedIn: false,
	isParticipant: false,
	userData: {},
	events: [],
};

const handlers = {
	[authTypes.SET_LOGGED_IN]: (state, { payload }) => ({ ...state, isLoggedIn: payload }),
	[authTypes.SET_USER_DATA]: (state, { payload }) => ({ ...state, userData: payload }),
	[authTypes.SET_PARTICIPANT]: (state, { payload }) => ({ ...state, isParticipant: payload }),
	[authTypes.SET_EVENTS]: (state, { payload }) => ({ ...state, events: payload }),
	DEFAULT: (state) => state,
};

const authReducer = (state = initalState, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action);
};

export default authReducer;
