import { createStore, combineReducers } from 'redux';
import pageReducer from './page/reducer';
import authReducer from './auth/reducer';
import beforeGameReducer from './beforeGameTabs/reducer';
import afterGameReducer from './afterGameTabs/reducer';
import loginPopupReducer from './loginPopup/reducer';

const reducers = combineReducers({
	pageReducer,
	authReducer,
	beforeGameReducer,
	afterGameReducer,
	loginPopupReducer,
});

// preloadedState will be passed in by the plugin
const store = (preloadedState) => {
	return createStore(reducers, preloadedState);
};

export default store;
